<template>
  <div class="main">
    <h1>分销平台登录</h1>
    <div class="w3_login">
      <div class="w3_login_module">
        <div class="module form-module">
          <!-- <div class="toggle">
            <i class="fa fa-times fa-pencil"></i>
            <div class="tooltip">Click Me</div>
          </div> -->
          
          <div class="form">
            <h2>Create an account</h2>
            <form action="#" method="post">
              <input
                type="text"
                name="Username"
                placeholder="Username"
                required=" "
              />
              <input
                type="password"
                name="Password"
                placeholder="Password"
                required=" "
              />
              <input
                type="email"
                name="Email"
                placeholder="Email Address"
                required=" "
              />
              <input
                type="text"
                name="Phone"
                placeholder="Phone Number"
                required=" "
              />
              <input type="submit" value="Register" />
            </form>
          </div>
          <div class="form">
            <h2>请登录</h2>
            <form action="#" method="post">
              <input
                type="text"
                name="用户名"
                placeholder="用户名"
                required=" "
              />
              <input
                type="password"
                name="密码"
                placeholder="密码"
                required=" "
              />
              <input type="submit" value="登录" />
            </form>
          </div>
          <!-- <div class="cta"><a href="#">Forgot your password?</a></div> -->
        </div>
      </div>
    </div>
    <div class="agileits_copyright">
      <p>
        版权所有 ©深圳市瑞麟数字科技控股有限公司
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2021043893号-5</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.main {
  font-size: 14px;
}
</style>
